const routes = {
  index: '/',
  presentations: '/presentations',
  repositories: '/repositories',
  editRepository: '/repository/:presentationId',
  build: '/build/:presentationId',
  logout: '/logout',
  shareSlide: '/share/slide/:contentRepoId/:slideId',
  shareDeck: '/share/deck/:contentRepoId/:deckId',
  reportPresentationsDownloads: '/reports/presentations-downloads',
  reportPresentationsCreate: '/reports/presentations-create',
  reportSlidesDownloads: '/reports/slides-downloads',
  reportDecksDownloads: '/reports/decks-downloads',
};

export const documentTitleMap = {
  presentations: 'Project Library',
  repositories: 'Welcome To The Slide Library',
  build: {
    document: 'Presentation:Build',
    page: 'Build'
  },
  reportPresentationsDownloads: {
    document: 'Reports:Presentations Downloads',
    page: 'Presentations Downloads',
  },
  reportPresentationsCreate: {
    document: 'Reports:Presentations Created',
    page: 'Presentations Created',
  },
  reportSlidesDownloads: {
    document: 'Reports:Slides Downloads',
    page: 'Slides Downloads',
  },
  reportDecksDownloads: {
    document: 'Reports:Decks Downloads',
    page: 'Decks Downloads',
  },
}

export default routes;
