import React from 'react';
import { useSelector } from 'react-redux';
import { platformSelectors } from '@clatter/platform';


const useContentTheme = () => {

  const selected = useSelector(({ platform: { contentThemes } }) => contentThemes.selected,)

  return { selected };
};

export default useContentTheme;
