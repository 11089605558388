import React, { lazy, Suspense, useEffect } from 'react';
import {
  BrowserRouter as Router,
  matchPath,
  Route,
  Switch,
} from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import {
  useAuth,
  Analytics,
  ProtectedRoute,
  LogoutPageView,
  fetchToolsConfig,
  fetchToolInfo,
  fetchFeatureFlag,
  fetchSystemProfile,
  sharedRoutes, fetchUserContentRepositories, useNotices,
} from '@clatter/platform';
import { Loader, AppThemeProvider, appGlobalStyles, SsoCallbackPage, TrackPageviewEvent } from '@clatter/ui';
import { useDispatch } from 'react-redux';
import routes from './routes';
import { userRolesMap } from './helpers/userRoles';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import {isRejected} from "@reduxjs/toolkit"; // theme css file

const LandingPageView = lazy(() =>
  import('./pages/LandingPage/LandingPageView'),
);

const SlideLibraryLandingPageView = lazy(() =>
  import('./pages/SlideLibraryLandingPage/SlideLibraryLandingPageView'),
);

const PresentationsPageView = lazy(() =>
  import('./pages/Presentations/PresentationsPageView'),
);

const BuildPageView = lazy(() => import('./pages/Build/BuildPageView'));

const SlideDetailsView = lazy(() =>
  import('./pages/DetailView/SlideDetails/SlideDetailsView'),
);

const DeckDetailsView = lazy(() =>
  import('./pages/DetailView/DeckDetails/DeckDetailsView'),
);

const PresentationsCreateReportView = lazy(() =>
  import('./pages/Reports/PresentationsCreate/PresentationsCreateReportView'),
);

const PresentationsDownloadsReportView = lazy(() =>
  import('./pages/Reports/PresentationsDownloads/PresentationsDownloadsReportView'),
);

const SlidesDownloadsReportView = lazy(() =>
  import('./pages/Reports/SlidesDownloads/SlidesDownloadsReportView'),
);
const DecksDownloadsReportView = lazy(() =>
  import('./pages/Reports/DecksDownloads/DecksDownloadsReportView'),
);

const GlobalStyle = createGlobalStyle`
  ${appGlobalStyles};
`;

export function App() {
  const dispatch = useDispatch();
  const { isAuthenticated, checkSession, user } = useAuth();
  const isOnLogoutPage = !!matchPath(location.pathname, {
    path: routes.logout,
    exact: true,
  });
  const isOnSsoCallbackPage = !!matchPath(location.pathname, {
    path: sharedRoutes.ssoCallback,
    exact: true,
  });

  useEffect(() => {
    dispatch(fetchToolInfo());
  }, [dispatch]);

  // set auth session after page refresh
  useEffect(() => {
    if (!isOnLogoutPage) {
      checkSession();
    }
  }, [checkSession]);

  // NOTE: Can't use 'addNotice' in the app level
  // because it is triggering infinite loop!!
  useEffect(() => {
    if (isAuthenticated && !isOnLogoutPage && !isOnSsoCallbackPage) {
      dispatch(fetchFeatureFlag());
      dispatch(fetchToolsConfig());
      dispatch(fetchSystemProfile());
      dispatch(fetchUserContentRepositories({ userId: user?.id }));
    }
  }, [dispatch, isAuthenticated, user?.id]);

  return (
    <AppThemeProvider>
      <GlobalStyle />
      <Router>
        <TrackPageviewEvent />
        <Analytics />

        <Suspense fallback={<Loader />}>
          <Switch>
            <Route exact path={routes.index} component={LandingPageView} />
            <Route exact path={routes.logout} component={LogoutPageView} />
            <Route exact path={sharedRoutes.ssoCallback} component={SsoCallbackPage} />
            <ProtectedRoute
              exact
              path={routes.presentations}
              component={PresentationsPageView}
              accessRoles={userRolesMap.slideLibraryAdmin}
            />
            <ProtectedRoute
              exact
              path={routes.repositories}
              component={SlideLibraryLandingPageView}
              accessRoles={[
                userRolesMap.slideLibrary,
                userRolesMap.slideLibraryAdmin,
              ]}
            />
            <ProtectedRoute
              exact
              path={routes.editRepository}
              component={SlideLibraryLandingPageView}
              accessRoles={userRolesMap.slideLibraryAdmin}
            />
            <ProtectedRoute
              exact
              path={routes.build}
              component={BuildPageView}
              accessRoles={[
                userRolesMap.slideLibrary,
                userRolesMap.slideLibraryAdmin,
              ]}
            />
            <ProtectedRoute
              exact
              path={[routes.shareSlide]}
              component={SlideDetailsView}
              accessRoles={[userRolesMap.slideLibrary, userRolesMap.slideLibraryAdmin]}
            />
            <ProtectedRoute
              exact
              path={[routes.shareDeck]}
              component={DeckDetailsView}
              accessRoles={[userRolesMap.slideLibrary, userRolesMap.slideLibraryAdmin]}
            />
            <ProtectedRoute
              exact
              path={routes.reportPresentationsCreate}
              component={PresentationsCreateReportView}
              accessRoles={[userRolesMap.slideLibraryAdmin]}
            />
            <ProtectedRoute
              exact
              path={routes.reportPresentationsDownloads}
              component={PresentationsDownloadsReportView}
              accessRoles={[userRolesMap.slideLibraryAdmin]}
            />
            <ProtectedRoute
              exact
              path={routes.reportSlidesDownloads}
              component={SlidesDownloadsReportView}
              accessRoles={[userRolesMap.slideLibraryAdmin]}
            />
            <ProtectedRoute
              exact
              path={routes.reportDecksDownloads}
              component={DecksDownloadsReportView}
              accessRoles={[userRolesMap.slideLibraryAdmin]}
            />
          </Switch>
        </Suspense>
      </Router>
    </AppThemeProvider>
  );
}
export default App;
