import React from 'react';
import { useSelector } from 'react-redux';
import { platformSelectors } from '@clatter/platform';

const useContentSlide = (selectedIds) => {
  const contentSlides = useSelector(
    platformSelectors.contentSlides.selectAllContentSlides,
  );

  const selectedContentSlidesCount = useSelector(
    platformSelectors.contentSlides.selectSelectedSlidesCount,
  );

  const selectedContentSlidesIds = useSelector(
    platformSelectors.contentSlides.selectSelectedContentSlidesIds,
  );

  const selectedContentSlide = useSelector(
    ({ platform: { contentSlides } }) => contentSlides.selectedContentSlide,
  );

  const dividerSlides = useSelector(
    ({ platform: { contentSlides } }) => contentSlides.dividerSlides,
  );

  const blankSlides = useSelector(
    ({ platform: { contentSlides } }) => contentSlides.blankSlides,
  );

  const coverSlides = useSelector(
    ({ platform: { contentSlides } }) => contentSlides.coverSlides,
  );

  const contentSlidesByFilters = useSelector(
    platformSelectors.contentSlides.selectContentSlidesByFilters,
  );

  const contentSlidesHierarchy = useSelector(
    platformSelectors.contentSlides.selectContentSlidesHierarchy,
  );

  const contentSlidesHierarchyIds = useSelector(
    platformSelectors.contentSlides.selectContentSlidesHierarchyIds,
  );

  const contentSlidesByIds = useSelector((state) =>
    platformSelectors.contentSlides.selectContentSlidesByIds(
      state,
      selectedIds,
    ),
  );

  const decksIds = useSelector(
    ({ platform: { contentSlides } }) => contentSlides.decksIds,
  );

  const loadingStatus = useSelector(
    ({ platform: { contentSlides } }) => contentSlides.loadingStatus,
  );

  return {
    contentSlides,
    selectedContentSlidesCount,
    selectedContentSlidesIds,
    selectedContentSlide,
    contentSlidesByFilters,
    contentSlidesHierarchy,
    contentSlidesHierarchyIds,
    contentSlidesByIds,
    blankSlides,
    coverSlides,
    dividerSlides,
    loadingStatus,
    decksIds,
  };
};

export default useContentSlide;
